import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CategoryCard from "../../../Components/CategoryCard/CategoryCard";
import EditCategoryForm from "../../../Components/form/EditCategoryForm";
import EditSubCategoryForm from "../../../Components/form/EditSubCategoryForm";
import Layout from "../../../Components/Layout/Layout";
import SimpleDialog from "../../../Components/SimpleDialog";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import * as YUP from "yup";
import {
  useAddUpdateCategoryMutation,
  useAddUpdateSubCategoryMutation,
  useCategoriesQuery,
} from "../../../services/endpoints/category.api";

const nameRequird = {
  name: YUP.string().required("field is required"),
};

const Category = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditSubCategoryModalOpen, setIsEditSubCategoryModalOpen] =
    useState(false);
  const [editCategory, setEditCategory] = useState();
  const [editSubCategory, setEditSubCategory] = useState();
  const [addUpdateCategory, { isLoading: addUpdateCategoryLoading }] =
    useAddUpdateCategoryMutation();
  const [addUpdateSubCategory, { isLoading: addUpdateSubCategoryLoading }] =
    useAddUpdateSubCategoryMutation();
  const {
    data: categories,
    isLoading: categoriesLoading,
    isFetching: categoriesFetching,
  } = useCategoriesQuery();
  const categoryFormik = useFormik({
    enableReinitialize: true,
    initialValues: editCategory ? editCategory : { name: "" },
    validationSchema: YUP.object().shape(nameRequird),
    onSubmit: async (values) => {
      categoryFormik.resetForm();
      togleEditModal();
      addUpdateCategory(values);
      setEditCategory();
      setEditSubCategory();
    },
  });
  const subCategoryFormik = useFormik({
    enableReinitialize: true,
    initialValues: editSubCategory ? editSubCategory : { name: "" },
    validationSchema: YUP.object().shape(nameRequird),
    onSubmit: async (values) => {
      const sanitizeValue = { ...values };
      if (!sanitizeValue?.parentXid) sanitizeValue.parentXid = editCategory._id;
      addUpdateSubCategory(sanitizeValue);
      subCategoryFormik.resetForm();
      togleEditSubCategoryModal();
    },
  });

  const togleEditModal = () => {
    setIsEditModalOpen((prev) => !Boolean(prev));
    setEditCategory();
    setEditSubCategory();
  };
  const togleEditSubCategoryModal = () => {
    setIsEditSubCategoryModalOpen((prev) => !Boolean(prev));
  };

  return (
    <Layout
      isLoading={
        categoriesLoading || categoriesFetching || addUpdateCategoryLoading
      }
    >
      <Paper sx={{ mx: 4, mt: 4, p: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Category</Typography>
          <IconButton onClick={() => setIsEditModalOpen("Add")}>
            <AddIcon />
          </IconButton>
        </Stack>
      </Paper>
      <Box sx={{ p: 4 }}>
        <CategoryCard
          categories={categories}
          togleEditModal={togleEditModal}
          togleSubCategory={togleEditSubCategoryModal}
          onEdit={(cat) => setEditCategory(cat)}
          onSubEdit={(cat) => setEditSubCategory(cat)}
        />

        <SimpleDialog
          isOpen={isEditModalOpen}
          title={`${isEditModalOpen === "Add" ? "Add New " : "Edit"}  Category`}
          handleClose={togleEditModal}
          action={
            <Button
              onClick={() => {
                categoryFormik.handleSubmit();
              }}
            >
              Submit
            </Button>
          }
        >
          <EditCategoryForm
            isAddNew={isEditModalOpen === "Add"}
            formik={categoryFormik}
            addNewCategory={(cat) => {
              setIsEditModalOpen(false);
              togleEditSubCategoryModal();
            }}
          />
        </SimpleDialog>
        <SimpleDialog
          isOpen={isEditSubCategoryModalOpen}
          title="Add or Edit SubCategory"
          handleClose={togleEditSubCategoryModal}
          action={
            <Button
              onClick={() => {
                subCategoryFormik.handleSubmit();
              }}
            >
              Submit
            </Button>
          }
        >
          <EditSubCategoryForm formik={subCategoryFormik} />
        </SimpleDialog>
      </Box>
    </Layout>
  );
};

export default Category;
