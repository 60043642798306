import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://calcio-backend.vercel.app",
  // baseUrl: "http://localhost:8080",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");

    if (token && token !== null && token !== "") {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const onRedirect = (callback) => {
  if (callback) callback();
  else window.location.reload();
};

const baseQueryWithReauth = async (args, theApi, extraOptions) => {
  let result = await baseQuery(args, theApi, extraOptions);
  if (result.error?.status === 432 || result.error?.status === 412) {
    localStorage.removeItem("token");
    onRedirect(() => {
      if (window.location.pathname != "/") window.location.pathname = "/";
    });
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["Admins", "Categories", "NFT"],
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 30,
  endpoints: () => ({}),
});
export default api;
