import { Box } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1 style={{ margin: 0 }}>404 Error</h1>
      <h4>Page Not Found</h4>
    </Box>
  );
};

export default NotFound;
