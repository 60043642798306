import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CategoryField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "22px",
  },
});
const EditCategoryForm = ({ category, addNewCategory, isAddNew, formik }) => {
  return (
    <Box>
      <Stack mb={2}>
        <CategoryField
          id="category-text-aligned"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name ? formik.errors.name : ""}
          label="Category"
        />
      </Stack>
      {!isAddNew && (
        <Button variant="text" onClick={() => addNewCategory(formik.values)}>
          Add New SubCategory
        </Button>
      )}
    </Box>
  );
};

export default EditCategoryForm;
