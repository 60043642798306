import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import { useCategoriesQuery } from "../../services/endpoints/category.api";
import { useState } from "react";
import DropDown from "../DropDown";
import { useEffect } from "react";
import Loader from "../Loader";

const CategoryField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "22px",
  },
});
const EditNft = ({ formik }) => {
  const {
    data: cateogries,
    isLoading,
    isFetching,
    isSuccess,
  } = useCategoriesQuery();
  const [subCategory, setSubCategory] = useState([]);
  const getSubCategory = () => {
    if (cateogries?.length) {
      return cateogries.find((cat) => cat._id === formik.values.category)
        ?.subCategory;
    }
    return [];
  };

  useEffect(() => {
    setSubCategory(getSubCategory());
  }, [cateogries, formik.values?.category]);
  return (
    <>
      {isLoading || isFetching ? (
        <Stack
          height={"100px"}
          direction="row"
          justifyItems={"center"}
          alignItems={"center"}
        >
          Loading
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <CategoryField
              name="price"
              value={formik.values?.price}
              onChange={formik.handleChange}
              label="price"
              error={formik.touched?.price && Boolean(formik.errors?.price)}
              helperText={formik.touched.price ? formik.errors.price : ""}
              fullWidth
            />
            <Stack>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.isOnSell}
                    onChange={formik.handleChange}
                    name="isOnSell"
                  />
                }
                label="Sell"
              />
            </Stack>
          </Stack>
          <Stack>
            {cateogries?.length && isSuccess && (
              <DropDown
                label={"Category"}
                value={formik.values.category}
                name="category"
                onChange={formik.handleChange}
                options={
                  cateogries?.length
                    ? cateogries.map((cat) => ({
                        label: cat.name,
                        value: cat._id,
                      }))
                    : []
                }
              />
            )}
          </Stack>
          <Stack>
            {subCategory?.length ? (
              <DropDown
                label={"SubCategory"}
                value={formik.values.subCategory}
                name="subCategory"
                onChange={formik.handleChange}
                options={
                  subCategory?.length
                    ? subCategory.map((cat) => ({
                        label: cat.name,
                        value: cat._id,
                      }))
                    : []
                }
              />
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default EditNft;
