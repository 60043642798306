import { Box } from "@mui/material";
import React from "react";
import styles from "./loader.module.css";

function Loader() {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        backdropFilter: "brightness(0.5)",
        height: "100vh",
        zIndex: 99999,
        top: 0,
        left: 0,
      }}
    >
      <div className={styles.tripleSpinnerContainer}>
        <div className={styles.tripleSpinner}></div>
      </div>
    </Box>
  );
}

export default Loader;
