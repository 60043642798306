import api from "../api";
import { updateErrorMessage } from "../commonSlice";
import qs from "query-string";
const NFTApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateNFT: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const categoryResponse = await fetchWithBQ({
            url: `/api/updatenft`,
            method: "POST",
            body: _arg,
          });
          //   console.log("categoryResponse", categoryResponse);
          if (categoryResponse.error) {
            _queryApi.dispatch(
              updateErrorMessage({
                message: categoryResponse.error?.data?.msg || "error",
                type: "error",
              })
            );
            throw categoryResponse.error;
          }
          _queryApi.dispatch(
            updateErrorMessage({
              message: categoryResponse?.data?.msg || "Success",
              type: "success",
            })
          );

          return categoryResponse?.data;
        } catch (error) {
          return error.status
            ? { error }
            : { error: { status: 400, data: error } };
        }
      },
      invalidatesTags: ["NFT"],
    }),
    addNFT: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const categoryResponse = await fetchWithBQ({
            url: `/api/addnft`,
            method: "POST",
            body: _arg,
          });

          if (categoryResponse.error) {
            _queryApi.dispatch(
              updateErrorMessage({
                message: categoryResponse.error?.data?.msg || "error",
                type: "error",
              })
            );
            throw categoryResponse.error;
          }
          _queryApi.dispatch(
            updateErrorMessage({
              message: categoryResponse?.data?.msg || "Success",
              type: "success",
            })
          );

          return categoryResponse?.data;
        } catch (error) {
          return error.status
            ? { error }
            : { error: { status: 400, data: error } };
        }
      },
      invalidatesTags: ["NFT"],
    }),

    getNfts: builder.query({
      query: (query) => {
        const q = qs.stringify(query);
        return {
          url: `/api/getnfts?${q}`,
        };
      },
      providesTags: ["NFT"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetNftsQuery, useUpdateNFTMutation, useAddNFTMutation } =
  NFTApi;
