import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../services/endpoints/authUserAdmin";
import Web3 from "web3";
import Loader from "../../Components/Loader";
import { useDispatch } from "react-redux";
import { updateErrorMessage } from "../../services/commonSlice";
import { ethers } from "ethers";
import web3modal from "web3modal";
import { providerOptions } from "../../providerOptions";

const web3Modal = new web3modal({
  providerOptions: providerOptions
})
const Login = () => {
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [network, setNetwork] = useState();
  const [account, setAccount] = useState("");

  useEffect(() => {
    localStorage.clear();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();

  const handleConnect = async () => {
    let accounts;
    let myProvider;
    let myLibrary;
    try {
      myProvider = await web3Modal.connect();
      myLibrary = new ethers.providers.Web3Provider(myProvider, "any");
      if (myLibrary) {
        setProvider(myProvider);
        setLibrary(myLibrary);
        accounts = await myLibrary.listAccounts();
        setAccount(accounts[0]);
        let network = await myLibrary.getNetwork();
        // if (network.chainId != id) {
        //   await switchNetwork(id, myLibrary, myProvider);
        //   network = await myLibrary.getNetwork();
        // }
        // setNetwork(network.chainId);

      }
      console.log("handleConnect end");

    } catch (e) {
      {
        console.log(e.message);
      }
    }

    let log = await login({
      address: accounts[0],
    });
    if (log?.error?.data?.msg)
      return dispatch(
        updateErrorMessage({
          message: log?.error?.data?.msg || "not authorize",
          type: "error",
        })
      );
    navigate("/admin/");
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      {isLoading && <Loader />}
      <Typography variant="h4" color="primary">
        Connect Wallet First
      </Typography>
      <Button variant="outlined" onClick={handleConnect}>
        Connect
      </Button>
      {/* <SimpleDialogDemo/> */}
    </Box>
  );
};

export default Login;
