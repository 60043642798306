import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {};

export const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    updateErrorMessage(state, action) {
      state.snackBar = action.payload;
    },
    updateAlertMessage(state, action) {
      state.alert = action.payload;
    },
  },
});

export const commonReducer = commonSlice.reducer;
export const { updateErrorMessage, updateAlertMessage } = commonSlice.actions;
