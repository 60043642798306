import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { createTheme, CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store } from "./services/store";

const mdTheme = createTheme({
  palette: {
    mode: "dark",
    mycolor: {
      main: "#607d8b",
    },

    secondary: {
      main: "#14171c",
      light: "#a0a4a7",
      dark: "#455a64",
      contrastText: "#a0a4a7",
    },
    background: {
      paper: "#121212",
      default: "#1b2026",
    },
    text: {
      primary: "#e0e0e0",
    },
    divider: "#fff",
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={mdTheme}>
      <ToastContainer theme="dark" />
      <Provider store={store}>
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
