import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const DropDown = ({ label, value, onChange, options, name, ...rest }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`label-${label}`}>{label}</InputLabel>
      <Select
        labelId={`label-${label}`}
        value={value}
        name={name}
        label={label}
        onChange={onChange}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem value={option?.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
