import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Web3 from "web3";
import Loader from "../../../Components/Loader";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getEllips } from "../../../helpers/common";
import Layout from "../../../Components/Layout/Layout";
import SimpleDialog from "../../../Components/SimpleDialog";
import AddNFT from "../../../Components/form/AddNFT";
import NftCard from "../../../Components/NftCard";
import {
  useGetNftsQuery,
  useUpdateNFTMutation,
} from "../../../services/endpoints/nft.api";
import EditNft from "../../../Components/form/EditNft";
import { useFormik } from "formik";
import * as YUP from "yup";
const Dashboard = () => {
  const navigate = useNavigate();
  const [isAddNFTModaOpen, setIsAddNFTModaOpen] = useState(false);
  const [nftForEdit, setNftforEdit] = useState();
  const [search, setSearch] = useState("");
  const [editNFtModalOpen, setEditNFtModalOpen] = useState();
  const togleAddNFTModal = () => {
    setIsAddNFTModaOpen(!isAddNFTModaOpen);
  };
  const {
    data: nftData,
    isLoading: nftsLoading,
    isFetching: nftsFetching,
  } = useGetNftsQuery({ isadmin: true, search });

  const [updateNFT, { isLoading }] = useUpdateNFTMutation();
  const editNFTFormik = useFormik({
    enableReinitialize: true,
    initialValues: { price: "", ...nftForEdit },
    validationSchema: YUP.object().shape({
      isOnSell: YUP.boolean(),
      category: YUP.string().required("field is required"),
      subCategory: YUP.string().required("field is required"),
      price: YUP.string().required("price is required"),
    }),
    onSubmit: async (values) => {
      editNFTFormik.resetForm();
      await updateNFT(values);
      setEditNFtModalOpen(false);
      setNftforEdit();
    },
  });
  return (
    <Layout isLoading={nftsLoading || nftsFetching}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: { sm: 0, md: 1, lg: 3 },
          }}
        >
          <Typography
            variant="h4"
            color={(theme) => theme.palette.getContrastText}
            component={"h4"}
            fontWeight="bold"
          >
            Admin Dashboard
          </Typography>
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "6px", flexGrow: 1 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{ flexGrow: 1 }}
              // onSubmit={handleSubmit}
            >
              <FormControl
                sx={{
                  width: "100%",
                  height: "37px",
                  "& .MuiOutlinedInput-root": { height: "37px" },
                }}
              >
                <OutlinedInput
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder="Search NFT by title or tokenid"
                />
              </FormControl>
            </Box>
          </Box>
          <Box>
            <Button
              color={"primary"}
              variant={"outlined"}
              onClick={togleAddNFTModal}
            >
              Add NFT
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", m: 3 }}>
          <Button></Button>
        </Box>
        <Container sx={{ padding: { xs: 1 } }}>
          <Grid container columnSpacing={1} rowSpacing={1}>
            {nftData?.nfts?.length ? (
              nftData?.nfts.map((nft) => (
                <Grid item xs={12} md={4}>
                  <Paper
                    sx={{ p: 1, border: "1px solid #e0e0e042" }}
                    onClick={() => {
                      setNftforEdit(nft);
                      setEditNFtModalOpen(true);
                    }}
                  >
                    <NftCard nft={nft} />
                  </Paper>
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      </Container>

      <SimpleDialog
        isOpen={editNFtModalOpen}
        handleClose={() => {
          setEditNFtModalOpen(false);
          setNftforEdit();
        }}
        title="Update NFT"
        action={
          <Button
            variant="contained"
            onClick={(e) => {
              editNFTFormik.handleSubmit(e);
            }}
          >
            Update
          </Button>
        }
      >
        <EditNft formik={editNFTFormik} />
      </SimpleDialog>
      <SimpleDialog
        isOpen={isAddNFTModaOpen}
        handleClose={togleAddNFTModal}
        title="Add New NFT"
      >
        <AddNFT onClose={togleAddNFTModal} />
      </SimpleDialog>
    </Layout>
  );
};

export default Dashboard;
