import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CategoryField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "22px",
  },
});
const EditSubCategoryForm = ({ formik }) => {
  return (
    <Stack>
      <CategoryField
        id="sub-category-text-aligned"
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name ? formik.errors.name : ""}
        label="Sub Category"
      />
    </Stack>
  );
};

export default EditSubCategoryForm;
