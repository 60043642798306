import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { MdManageAccounts, MdCategory, MdLogout } from "react-icons/md";
import { IconButton } from "@mui/material";
const drawerWidth = 240;

const sideBar = [
  {
    icon: <FaHome size={"1.75em"} />,
    name: "Home",
    route: "/admin/",
  },
  {
    icon: <MdManageAccounts size={"1.75em"} />,
    name: "Manage Admins",
    route: "/admin/manageadmins",
  },
  {
    icon: <MdCategory size={"1.75em"} />,
    name: "Category",
    route: "/admin/category",
  },
];
const Layout = ({ children, isLoading }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      {isLoading && <Loader />}
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Admin Panel
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleLogout}>
            <MdLogout />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {sideBar.map((item, index) => (
              <ListItem key={item.name + index} disablePadding>
                <ListItemButton onClick={() => navigate(item.route)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};
export default Layout;
