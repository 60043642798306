import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as YUP from "yup";
import { useAddNFTMutation } from "../../services/endpoints/nft.api";
const CategoryField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "22px",
  },
});
const AddNFT = ({ onClose }) => {
  const [addNFT, { isLoading }] = useAddNFTMutation();
  const formik = useFormik({
    initialValues: { token_id: "", token_address: "" },
    validationSchema: YUP.object().shape({
      token_id: YUP.string().required("price is required"),
    }),
    onSubmit: async (values) => {
      await addNFT(values);
      formik.resetForm();
      onClose();
    },
  });

  return (
    <Stack
      direction="row"
      sx={{ flexDirection: { md: "row", xs: "column" } }}
      justifyContent={"center"}
      alignItems={"center"}
      rowGap={1}
      columnGap={1}
      height={"100%"}
    >
      <CategoryField
        id="add-new-token-id-nft-input"
        value={formik.values.token_id}
        onChange={formik.handleChange}
        name="token_id"
        label="Token ID"
        error={formik.touched?.token_id && Boolean(formik.errors?.token_id)}
        helperText={formik.touched.token_id ? formik.errors.token_id : ""}
        fullWidth
      />
      <CategoryField
        id="add-new-token-address-nft-input"
        value={formik.values.token_address}
        onChange={formik.handleChange}
        name="token_address"
        label="Token Address"
        error={
          formik.touched?.token_address && Boolean(formik.errors?.token_address)
        }
        helperText={
          formik.touched.token_address ? formik.errors.token_address : ""
        }
        fullWidth
      />
      <Button onClick={formik.handleSubmit} disabled={isLoading}>
        {" "}
        {isLoading ? "Loading..." : "Add"}
      </Button>
    </Stack>
  );
};

export default AddNFT;
