import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Web3 from "web3";
import Loader from "../../../Components/Loader";
import { MdDelete } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../../Components/Layout/Layout";
import * as YUP from "yup";
import { useFormik } from "formik";
import {
  useAddAdminMutation,
  useDeleteAdminMutation,
  useGetAdminsQuery,
} from "../../../services/endpoints/authUserAdmin";
const columns = [
  { id: "name", label: "Name", minWidth: 220 },
  { id: "address", label: "User Adress", minWidth: 220 },
  {
    id: "delete",
    label: "Delete",
    minWidth: 100,
    maxWidth: 100,
    align: "right",
  },
];
const addAdminSchema = {
  address: YUP.string().required("Field is Required."),
  name: YUP.string().required("Field is Required."),
};
const ManageAdmins = () => {
  const navigate = useNavigate();
  const {
    data: admins,
    isLoading: isAdminsLoading,
    isFetching: isAdminsFetching,
  } = useGetAdminsQuery();
  const [deleteAdmin, { isLoading: isDeleteAdminLoading }] =
    useDeleteAdminMutation();
  const [addAdmin, { isLoading: isAddAdminLoading }] = useAddAdminMutation();
  const formik = useFormik({
    initialValues: { address: "", name: "" },
    validationSchema: YUP.object().shape(addAdminSchema),
    onSubmit: async (values) => {
      formik.resetForm();
      await addAdmin(values);
    },
  });
  const handleDelete = async (id) => {
    await deleteAdmin({ id });
  };
  return (
    <Layout
      isLoading={
        isAdminsLoading ||
        isAdminsFetching ||
        isDeleteAdminLoading ||
        isAddAdminLoading
      }
    >
      {/* {loading && <Loader />} */}

      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: { sm: 0, md: 1, lg: 3 },
          }}
        >
          <Typography
            variant="h4"
            color={(theme) => theme.palette.getContrastText}
            component={"h4"}
            fontWeight="bold"
          >
            Manage admins
          </Typography>
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            marginBottom: "20px",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "6px", flexGrow: 1 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}
              sx={{
                display: "flex",
                gap: "10px",
                rowGap: "30px",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <FormControl
                sx={{
                  width: "100%",
                  flex: "3 1 0",

                  minWidth: "25ch",
                  height: "37px",
                  "& .MuiOutlinedInput-root": { height: "37px" },
                }}
              >
                <OutlinedInput
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  name="address"
                  placeholder="enter address to login"
                />

                {formik.touched.address && Boolean(formik.errors.address) && (
                  <FormHelperText error>
                    {formik.touched.address ? formik.errors.address : ""}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  flex: "3 1 0",
                  minWidth: "25ch",
                  height: "37px",
                  "& .MuiOutlinedInput-root": { height: "37px" },
                }}
              >
                <OutlinedInput
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                  placeholder="enter name"
                />

                {formik.touched.name && Boolean(formik.errors.name) && (
                  <FormHelperText error>
                    {formik.touched.name ? formik.errors.name : ""}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  flex: "1 1 0",
                  minWidth: "25ch",
                  height: "37px",
                  "& .MuiOutlinedInput-root": { height: "37px" },
                }}
              >
                <Button type="submit">Add Admin</Button>
              </FormControl>
            </Box>
          </Box>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center", m: 3 }}>
              <Button></Button>
            </Box> */}
        <Paper>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, overflow: "auto" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {admins?.length ? (
                  admins.map((admin) => (
                    <TableRow
                      key={admin._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {admin.name}
                      </TableCell>
                      <TableCell>{admin.address}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(admin._id)}
                        >
                          <MdDelete color="#fff" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </Layout>
  );
};

export default ManageAdmins;
